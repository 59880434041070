import React from "react";
import Service from "./service";



interface Props {}

const Content = (props: Props) => {
	return (
		<section id="content">
			<div className="text-information" data-aos="fade-right" data-aos-duration="700">
				<h1>
					Make purchases
					<br />
					with Crypto
				</h1>
				<p>
					Fast and secure way to purchase store credit on any Moonsworth service using major cryptocurrencies.{" "}
					<span>Click a network to get started!</span>
				</p>
				<img className="no-drag" alt="List of cryptocurrencies supported" src="/assets/images/cryptolist.webp" height="47" width="428" />
				<div className="partner-disclaimer">
					<span>Disclaimer: </span>Cannot be used to purchase partner packages.
				</div>
			</div>
			<div className="row services-container">
				<div className="col-12">
					<Service
						checkoutId={"da820f4e-6864-44c7-b532-7fdbfda9cee4"}
						background={"/assets/images/lc_bg.webp"}
						icon={"/assets/images/lc_logo.webp"}
						title="Lunar Client"
						native={true}
						url={"https://store.lunarclient.com"}
					/>
				</div>
				<div className="col-6">
					<Service
						checkoutId={"170c4179-89fb-40bc-80a4-eb27e6c0f090"}
						background={"/assets/images/ln_bg.webp"}
						icon={"/assets/images/ln_logo.webp"}
						title="Lunar Network"
						native={true}
						url={"https://store.lunar.gg"}
					/>
				</div>
				<div className="col-6">
					<Service
						checkoutId={"fafe1a8c-84d9-44df-9c15-11925aefb52f"}
						background={"/assets/images/jmc_bg.webp"}
						icon={"/assets/images/jmc_logo.webp"}
						title="JailMC Network"
						native={true}
						url={"https://buy.jailmc.com/"}
					/>
				</div>
			</div>
		</section>
	);
};

export default Content;
