import React, { useState } from "react";
// @ts-ignore
import CoinbaseCommerceButton from "react-coinbase-commerce";

interface Props {
	checkoutId: string
	background: string
	icon: string
	title: string
	native: boolean
	url: string
}

const Service = (props: Props) => {
	const [isLoading, setIsLoading] = useState(false);

	const addEvent = (event?: string, data?: Object) => {
		// @ts-ignore
		if (typeof window === "undefined" && !window.document) return;
		
		// @ts-ignore
		window.dataLayer = window.dataLayer || [];
		// @ts-ignore
		window.dataLayer.push({
			"event": event,
			"network": props.title,
			"network_checkout_id": props.checkoutId,
			...data
		});
	};

	const onLoad = () => {
		setIsLoading(false);
		addEvent("crypto_modal_opened");
	};

	const onChargeSuccess = (data: any) => {
		addEvent("crypto_payment_success", {
			"code": data.code
		});
	};

	const onChargeFailure = (_data: any) => {
		addEvent("crypto_payment_failure");
	};

	return (
		<div className={`service-container${isLoading ? " network-display-loading" : ""}`} onClick={() => props.native ? window.open(props.url, '_blank') : setIsLoading(true)}>
			{!props.native ? (
				// Coinbase
				<CoinbaseCommerceButton
					onLoad={onLoad}
					onChargeSuccess={onChargeSuccess}
					onChargeFailure={onChargeFailure}
					className="network-display"
					checkoutId={props.checkoutId}
				>
					<div>
						<img
							className="network-bg no-drag"
							alt={`${props.title} Background`}
							src={props.background}
							placeholder="blur"
						/>
						<div>
							<img
								className="no-drag network-logo"
								alt={`${props.title} Logo`}
								src={props.icon}
								height="160"
								width="160"
							/>
							<h2>{props.title}</h2>
						</div>
						<div className="loading-overlay">
							<div className="spinner-grow" role="status"></div>
							<h2>Loading...</h2>
						</div>
					</div>
				</CoinbaseCommerceButton>
			):(
				// Native
				<div className="network-display" data-native="true">
					<div>
						<img
							className="network-bg no-drag network-bg-grey"
							alt={`${props.title} Background`}
							src={props.background}
							placeholder="blur"
						/>
						<div>
							<img
								className="no-drag network-logo"
								alt={`${props.title} Logo`}
								src={props.icon}
								height="160"
								width="160"
							/>
							<h2>{props.title}</h2>
							<div className="native-badge">
								Now Supported in Checkout!
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default Service;
