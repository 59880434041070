import React from "react";
interface Props {}

const Nav = (props: Props) => {
	return (
		<nav>
			<img
				src="/assets/images/logo.webp"
				alt="Moonsworth Logo"
				className="no-drag"
				height="72"
				width="72"
			/>
			<div>
				<div className="partner-disclaimer">
					<span>Disclaimer: </span>Cannot be used to purchase partner packages.
				</div>
				<a
					href="https://support.moonsworth.com/?utm_source=crypto-site&utm_medium=nav-cta&utm_campaign=lead"
					target="_blank"
					rel="noreferrer"
					aria-label="Contact Support"
				>
					Contact Support
				</a>
			</div>
		</nav>
	);
};

export default Nav;
