import type { NextPage } from "next";

// Components
import Footer from "../components/footer";
import Layout from "../components/layout";
import Nav from "../components/nav";
import Content from "../components/content";

const Home: NextPage = () => {
	return (
		<Layout>
			<Nav />
			<Content />
			<Footer />
		</Layout>
	);
};

export default Home;
